import { Routes } from '@angular/router';

import { LayoutComponent } from '../layout/layout.component';
import { AuthGuard } from '../core/services/auth.guard.service';
import { PaymentFailedGuard } from '../core/services/payment.failed.guard.service';

import { DemoRedirectComponent } from './pages/demo-redirect/demo-redirect.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { LinkedinCallbackComponent } from './pages/linkedin-callback/linkedin-callback.component';
import { RecoverPasswordComponent } from './pages/recover-password/recover-password.component';
import { RedirectGiftPlatformComponent } from './pages/redirect-gift-platform/redirect-gift-platform.component';
import { RedirectComponent } from './pages/redirect/redirect.component';
import { Redirectv2Component } from './pages/redirectv2/redirectv2.component';
import { RegisterMortgageComponent } from './pages/register-mortgage/register-mortgage.component';
import { RegisterComponent } from './pages/register/register.component';
import { SigninComponent } from './pages/signin/signin.component';
import { SignoutComponent } from './pages/signout/signout.component';
import { SsoComponent } from './pages/sso/sso.component';
import { TwitterCallbackComponent } from './pages/twitter-callback/twitter-callback.component';
import { DemoHandwrittenComponent } from './pages/demos/demo-handwritten/demo-handwritten.component';
import { DemoComponent } from './pages/demos/demo/demo.component';
import { GuildRegisterComponent } from './pages/signup-client/guild-register/guild-register.component';
import { SigninCTAComponent } from './pages/signin-cta/signin-cta.component';
import { RegisterCTAComponent } from './pages/register-cta/register-cta.component';
import { SignupHolidayComponent } from './pages/signup-holiday/signup-holiday.component';
import { SigninHolidayComponent } from './pages/signin-holiday/signin-holiday.component';
import { SetUpPasswordComponent } from './pages/set-up-password/set-up-password.component';
import { EvergreenRegisterComponent } from './pages/signup-client/evergreen-register/evergreen-register.component';
import { SideRegisterComponent } from './pages/signup-client/side-register/side-register.component';
import { JocelynRegisterComponent } from './pages/signup-client/jocelyn-register/jocelyn-register.component';
import { MillerRegisterComponent } from './pages/signup-client/miller-register/miller-register.component';


// import { DemoGifComponent } from './pages/demos/demo-gif/demo-gif.component';

export const routes: Routes = [
    {
        path: "",
        component: LayoutComponent,
        children: [
            { path: "", redirectTo: 'home', pathMatch: 'full' },
            { path: 'redirectOutlook', loadChildren: () => import('./pages/redirect-outlook/redirect-outlook.module').then(m => m.RedirectOutlookModule) },
            { path: 'redirectsso/:reqId', loadChildren: () => import('./pages/redirect-sso/redirect-sso.module').then(m => m.RedirectSSOModule) },
            { path: 'cart', loadChildren: () => import('./cart/cart.module').then(m => m.CartModule), canActivate: [PaymentFailedGuard] },
            { path: 'wishlist', loadChildren: () => import('./favourite/favourite.module').then(m => m.FavouriteModule), canActivate: [PaymentFailedGuard] },
            { path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomeModule), canActivate: [PaymentFailedGuard] },
            { path: 'giftStatus', loadChildren: () => import('./order-status/order-status.module').then(m => m.OrderStatusModule), canActivate: [PaymentFailedGuard] },
            { path: 'sendGift', loadChildren: () => import('./send-gift/send-gift.module').then(m => m.SendGiftModule), canActivate: [PaymentFailedGuard] },
            { path: 'referral', loadChildren: () => import('./referral/referral.module').then(m => m.ReferralModule), canActivate: [PaymentFailedGuard] },
            { path: 'rewards', loadChildren: () => import('./rewards/rewards.module').then(m => m.RewardModule), canActivate: [PaymentFailedGuard] },

            { path: 'branding', loadChildren: () => import('./branding/branding.module').then(m => m.BrandingModule), canActivate: [PaymentFailedGuard] },
            { path: 'branding/:type', loadChildren: () => import('./branding/branding.module').then(m => m.BrandingModule), canActivate: [PaymentFailedGuard] },

            { path: 'credits', loadChildren: () => import('./credit/credit.module').then(m => m.CreditModule), canActivate: [PaymentFailedGuard] },
            { path: 'card', loadChildren: () => import('./card/card.module').then(m => m.CardModule), canActivate: [PaymentFailedGuard] },
            { path: 'profile', loadChildren: () => import('./settings/profile/profile.module').then(m => m.ProfileModule), canActivate: [PaymentFailedGuard] },
            { path: 'changePassword', loadChildren: () => import('./settings/profile/profile.module').then(m => m.ProfileModule), canActivate: [PaymentFailedGuard] },
            // { path: 'giftCatalog', loadChildren: () => import('./catalog/catalog.module').then(m => m.CatalogModule), canActivate: [PaymentFailedGuard] },
            { path: 'customerData', loadChildren: () => import('./advanced-feature/client-research/client-research.module').then(m => m.ClientResearchModule), canActivate: [PaymentFailedGuard] },
            { path: 'team', loadChildren: () => import('./team/team.module').then(m => m.TeamModule), canActivate: [PaymentFailedGuard] },

            { path: 'invoice', loadChildren: () => import('./settings/invoice/invoice.module').then(m => m.InvoiceModule), canActivate: [PaymentFailedGuard] },

            { path: 'premiumFeatures', loadChildren: () => import('./premium-features/premium-features.module').then(m => m.PremiumFeatureModule), canActivate: [PaymentFailedGuard] },

            { path: 'bdap', loadChildren: () => import('./settings/bdap/bdap.module').then(m => m.BDAPModule), canActivate: [PaymentFailedGuard] },
            { path: 'faq', loadChildren: () => import('./faq/faq.module').then(m => m.FaqModule), canActivate: [PaymentFailedGuard] },
            // { path: 'exploreGifts', loadChildren: () => import('./explore-gift/explore-gift.module').then(m => m.ExploreGiftModule), canActivate: [PaymentFailedGuard] },
            // { path: 'exploreTags', loadChildren: () => import('./explore-gift-tag/explore-gift-tag.module').then(m => m.ExploreGiftTagModule), canActivate: [PaymentFailedGuard] },
            { path: 'campaign', loadChildren: () => import('./advanced-feature/campaign/campaign.module').then(m => m.CampaignModule), canActivate: [PaymentFailedGuard] },
            { path: 'birthday', loadChildren: () => import('./advanced-feature/automated-gifts/automated-gifts.module').then(m => m.AutomatedGiftsModule), canActivate: [PaymentFailedGuard] },
            { path: 'automatedGift', loadChildren: () => import('./advanced-feature/automated-gifts/automated-gifts.module').then(m => m.AutomatedGiftsModule), canActivate: [PaymentFailedGuard] },
            { path: 'automatedGift/:type', loadChildren: () => import('./advanced-feature/automated-gifts/automated-gifts.module').then(m => m.AutomatedGiftsModule), canActivate: [PaymentFailedGuard] },
            { path: 'emailTemplate', loadChildren: () => import('./settings/email-template/email-template.module').then(m => m.TemplateModule), canActivate: [PaymentFailedGuard] },
            { path: 'giftSettings', loadChildren: () => import('./settings/gift-settings/gift-settings.module').then(m => m.GiftSettingModule), canActivate: [PaymentFailedGuard] },
            { path: 'giftSettings/:type', loadChildren: () => import('./settings/gift-settings/gift-settings.module').then(m => m.GiftSettingModule), canActivate: [PaymentFailedGuard] },
            { path: 'emailPreferences', loadChildren: () => import('./settings/email-preferences/email-preferences.module').then(m => m.EmailPreferenceModule), canActivate: [PaymentFailedGuard] },
            { path: 'myPlan', loadChildren: () => import('./settings/my-plan/my-plan.module').then(m => m.MyPlanModule), canActivate: [PaymentFailedGuard] },
            { path: 'giftPlatform', loadChildren: () => import('./gift-platform/gift-platform.module').then(m => m.GiftPlatformModule), canActivate: [PaymentFailedGuard] },
            { path: 'giftPlatformCart', loadChildren: () => import('./gift-platform-cart/gift-platform-cart.module').then(m => m.GiftPlatformCartModule), canActivate: [PaymentFailedGuard] },
            
            { path: 'guild/plan', loadChildren: () => import('./offers/guild/guild.module').then(m => m.GuildOfferModule), canActivate: [PaymentFailedGuard] },
            { path: 'svb/plan', loadChildren: () => import('./offers/svb/svb.module').then(m => m.SVBPlanModule), canActivate: [PaymentFailedGuard] },
            { path: 'guidelines', loadChildren: () => import('./offers/guidelines/guidelines.module').then(m => m.GuidelinesModule), canActivate: [PaymentFailedGuard] },

            { path: 'planDetails', loadChildren: () => import('./plan-detials/plan-detial.module').then(m => m.PlanDetailModule), canActivate: [PaymentFailedGuard] },
            { path: 'addresses', loadChildren: () => import('./settings/address/address.module').then(m => m.AddressModule), canActivate: [PaymentFailedGuard] },
           
            // { path: 'giftOptions/:orderHashId', loadChildren: () => import('./gift-options/gift-options.module').then(m => m.GiftOptionsModule) },
            { path: 'integration/:type', loadChildren: () => import('./integrations/integration.module').then(m => m.IntegrationModule), canActivate: [PaymentFailedGuard] },
            { path: 'integration', loadChildren: () => import('./integrations/integration.module').then(m => m.IntegrationModule), canActivate: [PaymentFailedGuard] },
            { path: 'verifyEmail/:userHashId', loadChildren: () => import('./verify-email/verify-email.module').then(m => m.VerifyEmailModule), canActivate: [PaymentFailedGuard] },

            { path: 'ob', loadChildren: () => import('./on-boarding/on-boarding.module').then(m => m.OnBoardingModule) },


            { path: 'subscription', loadChildren: () => import('./universal/subscription/subscription.module').then(m => m.SubscriptionModule), canActivate: [PaymentFailedGuard] },
            { path: 'order', loadChildren: () => import('./universal/order/order.module').then(m => m.OrderModule) },

            { path: 'directMailer', loadChildren: () => import('./direct-mailer/direct-mailer.module').then(m => m.DirectMailerModule), canActivate: [PaymentFailedGuard] },
            { path: 'surpriseGift', loadChildren: () => import('./civic-list/civic-list.module').then(m => m.CivicListModule) },

            // { path: 'directMailerPlan', loadChildren: () => import('./direct-mailer-plan/direct-mailer-plan.module').then(m => m.DirectMailerPlanModule), canActivate: [PaymentFailedGuard] },
            // { path: 'buyDirectMailers', loadChildren: () => import('./buy-direct-mailer/buy-direct-mailer.module').then(m => m.BuyDirectMailerModule), canActivate: [PaymentFailedGuard] },
            { path: 'obv2', loadChildren: () => import('./on-boarding-v2/on-boarding-v2.module').then(m => m.OnBoardingV2Module) },

        ]
    },
    // { path: 'login', component: SigninComponent, canActivate: [AuthGuard] },
    { path: 'login-cta', component: SigninCTAComponent, canActivate: [AuthGuard] },
    // { path: 'signup', component: RegisterComponent, canActivate: [AuthGuard] },
    { path: 'signup-cta', component: RegisterCTAComponent, canActivate: [AuthGuard] },
    { path: 'signup', component: SignupHolidayComponent, canActivate: [AuthGuard] },

    { path: 'login', component: SigninHolidayComponent, canActivate: [AuthGuard] },
    { path: 'setUpPassword', component: SetUpPasswordComponent, canActivate: [AuthGuard] },

    { path: 'sso', component: SsoComponent, canActivate: [AuthGuard] },
    { path: 'guild', component: GuildRegisterComponent },
    { path: 'evergreen', component: EvergreenRegisterComponent },
    { path: 'side', component: SideRegisterComponent },
    { path: 'millerregroup', component: MillerRegisterComponent },
    { path: 'eva-signup', component: JocelynRegisterComponent },
    { path: 'mortgageSignUp', component: RegisterMortgageComponent, canActivate: [AuthGuard] },
    { path: 'redirectGoogleV1', component: RedirectComponent, canActivate: [AuthGuard] },
    { path: 'demoRedirect', component: DemoRedirectComponent },
    { path: 'redirectGoogleV2', component: Redirectv2Component },
    { path: 'twitterCallback', component: TwitterCallbackComponent },
    { path: 'linkedinCallback', component: LinkedinCallbackComponent },


    { path: 'forgotPassword', component: ForgotPasswordComponent },
    { path: 'recoverPassword', component: RecoverPasswordComponent },
    { path: 'redirectGiftPlatform', component: RedirectGiftPlatformComponent },

    { path: 'eva-demo', component: DemoComponent, canActivate: [AuthGuard] },
    { path: 'eva-demo-handwritten', component: DemoHandwrittenComponent, canActivate: [AuthGuard] },
    { path: 'eva-demo-yt', component: DemoComponent, canActivate: [AuthGuard] },
    { path: 'eva-demo-rt', component: DemoComponent, canActivate: [AuthGuard] },
    { path: 'eva-demo-ytk', component: DemoComponent, canActivate: [AuthGuard] },
    { path: 'eva-demo-yta', component: DemoComponent, canActivate: [AuthGuard] },
    //{ path: 'demo', component: DemoGifComponent, canActivate: [AuthGuard] },

    { path: 'logout', component: SignoutComponent },

    { path: "**", redirectTo: 'home' },

]