<div class="signup-section">
  <div *ngIf="!isMobile" class="signup-info-section">
    <div class="signup-info">
      <div style="display: flex; gap: 10px">
        <img width="100%" src="https://s3.amazonaws.com/eva-bot/img/47079c51-1e2b-4b1f-82f3-40317e6952f8.png	" />
      </div>
      <div class="element-gap-42"></div>
      <div style="margin: 2%;" class="font-40 font-bold-400 black">Let's get started</div>

      <div style="position: absolute; bottom: 5%">
        <div class="font-14 font-bold-400 black">Already have an account?</div>
        <div>
          <a
            [routerLink]="['/login-holiday']"
            style="color: #5100ff !important; cursor: pointer"
            class="font-14 font-bold-400 cta"
            >Login to Eva</a
          >
        </div>
      </div>
    </div>
    <div class="signup-info-man-icon" style="width: 45%">
      <img
        style="max-width: 80%"
        src="../../../../assets/img/signup/signup-woman.svg"
      />
    </div>
  </div>

  <div class="signup-form-section">
    <div class="signup-form">
      <div *ngIf="isMobile">
        <div>
          <img src="https://s3.amazonaws.com/eva-bot/img/47079c51-1e2b-4b1f-82f3-40317e6952f8.png	" />
        </div>
        <div class="element-gap-16"></div>
        <div class="font-24 font-bold-400 black">Let's get started</div>
        <div class="element-gap-16"></div>
      </div>
      <div class="font-24 font-bold-500 black">
        Tell us about yourself first
      </div>
      <!-- <div class="element-gap-16"></div> -->
      <!-- <div>
                <a href="https://client.stage.evabot.com/user/googleLogin" class="btn-google"><img
                        src="../../../../assets/img/signup/google-logo.svg">&nbsp;&nbsp;Sign up
                    with Google</a>
            </div> -->
      <!-- <div class="element-gap-16"></div>
            <div class="font-14 font-bold-400 disabled-text">Or use your email for sign up</div> -->
      <div class="element-gap-16"></div>
      <form class="mb-3" (ngSubmit)="onSignUp(f)" #f="ngForm">
        <div class="form-row">
          <div class="col-md-12">
            <label class="form-label font-14" for="first-name"
              >Full name<sup>*</sup></label
            >
            <div class="element-gap-4"></div>
            <input
              id="first-name"
              [(ngModel)]="userFirstName"
              class="form-control"
              type="text"
              placeholder="e.g. Sarah Jones"
              name="firstname"
              ngModel
              autocomplete="off"
              #firstName="ngModel"
              [ngClass]="{ 'input-error': isSubmitted && !firstName.valid }"
              required
            />
            <div
              *ngIf="isSubmitted && !firstName.valid"
              class="form-input-error"
            >
              Kindly enter the full name
            </div>
          </div>
        </div>
        <div class="element-gap-16"></div>
        <div class="form-row">
          <div class="col-md-12">
            <label class="form-label font-14" for="user-email"
              >Work email<sup>*</sup></label
            >
            <div class="element-gap-4"></div>
            <input
              placeholder="e.g. sarah@company.com"
              id="user-email"
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,64}$"
              [(ngModel)]="userEmail"
              class="form-control"
              type="email"
              name="email"
              ngModel
              oninput="this.value = this.value.toLowerCase()"
              autocomplete="off"
              #email="ngModel"
              [ngClass]="{ 'input-error': isSubmitted && !email.valid }"
              required
              email
            />
            <div *ngIf="isSubmitted && !email.valid" class="form-input-error">
              Enter a valid work email
            </div>
          </div>
        </div>
        <div class="element-gap-16"></div>

        <!-- <div class="form-row">
                    <div class="col-md-12">
                        <label class="form-label font-14" for="user-email">How many gifts do you plan to send this
                            holiday season?<sup>*</sup></label>
                        <div class="element-gap-4"></div>
                        <select name="noOfGifts" id="no-of-gifts" ngModel #noOfGift="ngModel" [(ngModel)]="noOfGifts"
                            style="height: 48px;padding: 12px 12px;border-radius: 6px;"
                            class="form-control font-16 font-bold-400"   [ngClass]="{'input-error':isSubmitted&& !noOfGift.valid}"  required>
                            <option value="">Select the number of gifts</option>
                            <option value="<100">Less than 100</option>
                            <option value="100-200">100 to 200 </option>
                            <option value="200-500">200 to 500</option>
                            <option value=">500">More than 500</option>
                        </select>

                        <div *ngIf="isSubmitted&& !noOfGift.valid" class="form-input-error">Please select the number of
                            gifts you plan to send
                        </div>
                    </div>

                </div> -->
        <div class="form-row">
          <div class="col-md-12">
            <label class="form-label font-14" for="user-email"
              >What is your gifting budget?<sup>*</sup></label
            >
            <div class="element-gap-4"></div>
            <select
              name="giftingbudget"
              id="gifting-budget"
              ngModel
              #giftingbudget="ngModel"
              [(ngModel)]="giftingBudget"
              style="height: 48px; padding: 12px 12px; border-radius: 6px"
              class="form-control font-16 font-bold-400"
              [ngClass]="{ 'input-error': isSubmitted && !giftingbudget.valid }"
              required
            >
              <option value="">Select the budget range</option>
              <option value="Less than $1000">Less than $1000</option>
              <option value="$1000 to $3000">$1000 to $3000</option>
              <option value="$3000 to $5000">$3000 to $5000</option>
              <option value="$5000 to $10000">$5000 to $10000</option>
              <option value="$10000 to $20000">$10000 to $20000</option>
              <option value="More than $20000">More than $20000</option>
            </select>

            <div
              *ngIf="isSubmitted && !giftingbudget.valid"
              class="form-input-error"
            >
              Select the gifting budget
            </div>
          </div>
        </div>
        <div class="element-gap-16"></div>
        <div class="form-row">
          <div class="col-md-12">
            <label class="form-label font-14" for="user-email"
              >What do you want to use Eva for?<sup>*</sup></label
            >
            <div class="element-gap-4"></div>
            <select
              name="evausage"
              id="eva-usages"
              ngModel
              #evausage="ngModel"
              [(ngModel)]="evaUsage"
              style="height: 48px; padding: 12px 12px; border-radius: 6px"
              class="form-control font-16 font-bold-400"
              [ngClass]="{ 'input-error': isSubmitted && !evausage.valid }"
              required
            >
              <option value="">
                Select the kind the gifts you wish to send
              </option>
              <option value="Customer engagement">Customer engagement</option>
              <option value="Employee engagement">Employee engagement</option>
              <option value="Sales engagement">Sales engagement</option>
              <option value="Account based marketing">
                Account based marketing
              </option>
              <option value="Others">Others</option>
            </select>

            <div
              *ngIf="isSubmitted && !evausage.valid"
              class="form-input-error"
            >
              Select the type of gift you wish to send
            </div>
          </div>
        </div>
        <div class="element-gap-16"></div>
        <div class="form-row">
          <div class="col-md-12">
            <label class="form-label font-14" for="user-email"
              >How often do you wish to use Eva?<sup>*</sup></label
            >
            <div class="element-gap-16"></div>
            <div style="display: flex; gap: 20px">
              <div class="c-radio">
                <label>
                  <input
                    type="radio"
                    name="fre"
                    value="One time"
                    [(ngModel)]="EvaUsageFrequency"
                  />
                  <span class="fa fa-check align-middle"></span>One time
                </label>
              </div>
              <div class="c-radio">
                <label>
                  <input
                    type="radio"
                    name="fre"
                    value="Repetitive"
                    [(ngModel)]="EvaUsageFrequency"
                  />
                  <span class="fa fa-check align-middle"></span>Repetitive
                </label>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="form-row">
                    <div class="form-group col-md-12">
                        <label class="form-label font-14" for="user-password">Create password</label>
                        <div class="element-gap-4"></div>
                        <div class="input-group">
                            <input (keyup)="setPopover()" [popover]="referralLinkInfo" #pop4="bs-popover"
                                placement="left" id="user-password" type="password"
                                pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[-+=@$#^!%*?()&.<>{}[]|;:''?`~/])[A-Za-z\d-+=@$#^()!%*?&.<>{}[]|;:''?`~/]$"
                                class="form-control pass-strength" ngModel name="password" #password="ngModel"
                                [(ngModel)]="pass" [ngClass]="{'input-error':isSubmitted && password.invalid}"
                                autocomplete="off" required>

                            <ng-template #referralLinkInfo>
                                <div style="position: relative;">
                                    <div>
                                        <div class="font-16 font-bold-500">{{passwordStrengthText}}</div>
                                        <div class="element-gap-16"></div>
                                        <app-password-strength
                                            (getPasswordStrengthText)="setPasswordStrengthText($event)"
                                            [passwordToCheck]="pass" [barLabel]=""></app-password-strength>
                                        <div class="element-gap-16"></div>
                                        <p style="padding:0 ; margin: 0;" class="font-14 font-bold-500">Must have:
                                        </p>
                                        <div>
                                            <ul id="password-validation-list" class="font-12 font-bold-400"
                                                style="line-height: 2.4;">
                                                <li>
                                                    <img *ngIf="uppercaseRegex.test(password.value)"
                                                        src="../../../../assets/img/signup/green-tick.svg">
                                                    <span style="color:  #CCCCCC;"
                                                        *ngIf="!(uppercaseRegex.test(password.value))"
                                                        class="fa fa-circle"></span>&nbsp;<span
                                                        [ngClass]="{'strikeout': uppercaseRegex.test(password.value)}">1
                                                        Upper case letter</span>
                                                </li>
                                                <li> <img *ngIf="lowercaseRegex.test(password.value)"
                                                        src="../../../../assets/img/signup/green-tick.svg"><span
                                                        style="color:  #CCCCCC;"
                                                        *ngIf="!(lowercaseRegex.test(password.value))"
                                                        class="fa fa-circle"></span>&nbsp; <span
                                                        [ngClass]="{'strikeout': lowercaseRegex.test(password.value)}">1
                                                        Lower case letter</span></li>
                                                <li> <img *ngIf="specialcharcterRegex.test(password.value)"
                                                        src="../../../../assets/img/signup/green-tick.svg"><span
                                                        style="color:  #CCCCCC;"
                                                        *ngIf="!(specialcharcterRegex.test(password.value))"
                                                        class="fa fa-circle"></span>&nbsp;<span
                                                        [ngClass]="{'strikeout': specialcharcterRegex.test(password.value)}">1
                                                        Special character</span></li>
                                                <li> <img *ngIf="numberRegex.test(password.value)"
                                                        src="../../../../assets/img/signup/green-tick.svg"><span
                                                        style="color:  #CCCCCC;"
                                                        *ngIf="!(numberRegex.test(password.value))"
                                                        class="fa fa-circle"></span>&nbsp;<span
                                                        [ngClass]="{'strikeout': numberRegex.test(password.value)}">1
                                                        Number</span></li>
                                                <li>
                                                    <img *ngIf="password.value.length>=8"
                                                        src="../../../../assets/img/signup/green-tick.svg">
                                                    <span *ngIf="!(password.value.length>=8)" style="color:  #CCCCCC;"
                                                        class="fa fa-circle"></span>&nbsp;<span
                                                        [ngClass]="{'strikeout': password.value.length>=8}">8
                                                        characters</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </div>

                    </div>


                </div> -->

        <!-- <div *ngIf="isSubmitted && password.invalid">
                    <div>
                        <div class="black font-12">Password must contain the following:</div>
                        <div
                            [ngClass]="{'form-input-error': !(lowercaseRegex.test(password.value)), 'form-input-success':lowercaseRegex.test(password.value)}">
                            <span *ngIf="lowercaseRegex.test(password.value)" class="fa fa-check"></span><span
                                *ngIf="!(lowercaseRegex.test(password.value))" class="fa fa-times"></span>&nbsp;A
                            lowercase letter
                        </div>
                        <div
                            [ngClass]="{'form-input-error': !(uppercaseRegex.test(password.value)), 'form-input-success':uppercaseRegex.test(password.value)}">
                            <span *ngIf="uppercaseRegex.test(password.value)" class="fa fa-check"></span><span
                                *ngIf="!(uppercaseRegex.test(password.value))" class="fa fa-times"></span>&nbsp;A
                            Capital (uppercase) letter
                        </div>
                        <div
                            [ngClass]="{'form-input-error': !(numberRegex.test(password.value)), 'form-input-success':numberRegex.test(password.value)}">
                            <span *ngIf="numberRegex.test(password.value)" class="fa fa-check"></span><span
                                *ngIf="!(numberRegex.test(password.value))" class="fa fa-times"></span>&nbsp;A Number
                        </div>
                        <div
                            [ngClass]="{'form-input-error': !(specialcharcterRegex.test(password.value)), 'form-input-success':specialcharcterRegex.test(password.value)}">
                            <span *ngIf="specialcharcterRegex.test(password.value)" class="fa fa-check"></span><span
                                *ngIf="!(specialcharcterRegex.test(password.value))" class="fa fa-times"></span>&nbsp;A
                            special character
                        </div>
                    </div>
                </div> -->
        <div class="element-gap-24"></div>

        <button class="button-primary button-medium" type="submit">
          Continue&nbsp;&nbsp;<img
            src="../../../../assets/img/icons/right-arrow.svg"
          />
        </button>
        <div class="element-gap-16"></div>
        <div *ngIf="isInvalid" class="error-bar">
          <div class="error-bar-icon">
            <span class="fa fa-exclamation-circle"></span>
          </div>
          <div class="error-bar-text">
            <div class="font-14 font-bold-400">
              Email address is already registered
            </div>
            <div class="font-12 font-bold-400">
              Sign up with different email or
              <span class="font-bold-500"
                ><a
                  style="color: #0094e3 !important"
                  [routerLink]="['/login-holiday']"
                  >Login</a
                ></span
              >
              with current email
            </div>
          </div>
        </div>
        <div  class="black font-14 font-bold-400 ">Have a questions?<br>Email us at <a href="mailto:travis@jocelynco.com">travis@jocelynco.com</a> and we will get back ASAP</div>
      </form>
    </div>
    <div class="p-3 text-center" style="position: absolute;bottom:0">
      <span>&copy; 2024 - EvaBot Inc. All Rights Reserved.</span>
    </div>
  </div>
</div>
